import React, { useState, useContext } from "react";
import { Card } from "flowbite-react";
import { Button, Label, TextInput, Select, Modal } from "flowbite-react";
import { HiMail } from "react-icons/hi";
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@mui/x-data-grid';
import './style/History.css';
import axios from "axios";
import UserContext from '../../../../API/UserContext';
import { useNavigate } from 'react-router-dom';
// Function to format date
import { Toaster, toast } from 'sonner'

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr', { year: 'numeric', month: 'short', day: 'numeric' });
}

function OperationsHistory({ history, balance }) {

    const [openModal, setOpenModal] = useState(false);
    const [motif, setmotif] = useState("");
    const [amount, setamount] = useState("0");
    const [compte, setcompte] = useState("1");
    const [historyData, setHistoryData] = useState(history);
    const { user } = useContext(UserContext);
    const Navigate = useNavigate();


    const fetchBalanceData = async () => {
        try {
            const token = user.token; // Replace this with your actual JWT token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const response = await axios.get("https://mood.ovh:5000/User/Balance/GetBalance", config);
            setHistoryData(response.data.OperationsHistory);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Redirect to login page if status code is 401
                Navigate('/login');
            } else {
                console.error("Error fetching balance data:", error);
            }
        }
    };


    // Map over history and format created_at dates
    const formattedHistory = historyData.map(item => {
        return {
            ...item,
            created_at: formatDate(item.created_at)
        };
    });

    const handleInsert = () => {
        if (motif && motif != "" && amount && amount != "" && compte && compte != "") {

            if (parseInt(amount) <= parseInt(balance.Wallet)) {


                const newCase = {
                    Motif: motif,
                    Valeur: amount,
                    BankAccount: compte

                };
                console.log({ newCase })
                axios.post('https://mood.ovh:5000/User/Operations/WithdrawalRequest/Insert', newCase, {
                    headers: {
                        Authorization: `Bearer ${user.token}`
                    }
                })
                    .then(response => {
                        setOpenModal(false);

                        toast.success('Sent ! ');
                        fetchBalanceData();

                        // Navigate('/?r=in')
                        // Optionally, you may want to update the cases list here
                    })
                    .catch(error => {
                        setOpenModal(false);
                        toast.error('Error E147');

                        ///  Navigate('/?r=er')
                    });


            } else {

                toast.error('Low Balance !');

                setOpenModal(false);

            }
        } else {
            toast.error('Please Fill all the inputs')

        }

    };





    return (
        <div>
            <Toaster expand={false} richColors position="top-right" />

            <Card className="mt-2 card md:mx-12" style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: '1', padding: '1em 0', overflowY: 'auto' }}>
                    <div className="flex justify-between items-center  mb-12">
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Operations History
                        </h5>
                        <p className="text-l font-semibold ">
                            <Button variant="contained" style={{ backgroundColor: '#e53d51', marginRight: '0.5em' }} onClick={() => setOpenModal(true)} startIcon={<AddIcon />}>Request Withdrawal</Button>
                        </p>
                    </div>
                    <DataGrid
                        rows={formattedHistory}
                        columns={[
                            { field: 'created_at', headerName: 'Date Opération', width: 200 },
                            { field: 'Motif', headerName: 'Motif', width: 500 },
                            {
                                field: 'Valeur', headerName: 'Valeur', width: 200, cellClassName: (params) => {
                                    return `custom-valeur-cell ${params.row.Type === 'Cash-out' ? 'cash-out' : 'cash-in'}`;
                                }
                            },
                            {
                                field: 'Type', headerName: "Type de l'Opération", width: 200, cellClassName: (params) => {
                                    return `custom-valeur-col ${params.row.Type === 'Cash-out' ? 'cash-out' : 'cash-in'}`;
                                }
                            },
                            { field: 'Status', headerName: 'Status', width: 300 },
                        ]}
                        pageSize={5}
                        style={{ height: '20em' }}
                    />
                </div>
            </Card>





            <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)}>
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">Demande de retrait</h3>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="motif" value="Motif" />
                            </div>
                            <TextInput placeholder="Ex. Retrait des gains" id="motif" type="text" required onChange={(e) => setmotif(e.target.value)} />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="amount" value="Montant" />
                            </div>
                            <TextInput id="amount" type="number" min="0" max={balance.Wallet} required onChange={(e) => setamount(e.target.value)} />
                        </div>

                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="compte" value="Compte banquaire" />
                            </div>
                            <Select id="compte" required onChange={(e) => setcompte(e.target.value)}>
                                <option value="1">ATB - TN54 1454 5588 5252 22</option>
                            </Select>
                        </div>
                        <div className="flex justify-center"> {/* Centering the submit button */}
                            <Button style={{backgroundColor:'#e53d51'}} onClick={handleInsert}>Envoyer la demande</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>



        </div>


    );
}

export default OperationsHistory;
