import React from "react";
import { DataGrid } from '@mui/x-data-grid';

function LastLogins({ userData }) {
    const authentications = userData?.Authentications;

    if (!authentications || authentications.length === 0) {
        return <div>No authentication history available</div>;
    }

    const columns = [
        { field: 'IP', headerName: 'IP', width: 180 },
        { field: 'date', headerName: 'Date', width: 200 },
    ];

    const rows = authentications.map(authentication => ({
        id: authentication.id,
        IP: authentication.IP,
        date: new Date(authentication.created_at).toLocaleString(),
    }));

    return (
        <div className="bg-white p-3 hover:shadow">
            <div className="flex items-center space-x-3 font-semibold text-gray-900 text-xl leading-8">
                <span style={{ color: '#e53d51' }}>
                    <svg className="h-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                </span>
                <span>Authentication History</span>
            </div>
            <div>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    style={{ height: '30vh' }}
                />
            </div>
        </div>
    );
}

export default LastLogins;
