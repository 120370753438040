import React from "react";
import { Card } from "flowbite-react";
import ReactApexChart from 'react-apexcharts';
import './style/XpBalance.css';

function XpBalance({ balance }) {
    const chartData = {
        series: [balance.XP , balance.Level  , balance.Cases , 100],
        colors: ['#e53d51', '#0084ff', '#4caf50', '#ffc107'],
        labels: ['XP Points', 'Level', 'Conversions', 'Performance'],
        options: {
            chart: {
                height: 390,
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    background: '#fff',
                    offsetY: 0,
                    startAngle: 0,
                    endAngle: 270,
                    hollow: {
                        margin: 5,
                        size: '30%',
                        background: 'transparent',
                        image: undefined,
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            show: false,
                        }
                    },
                    barLabels: {
                        enabled: true,
                        useSeriesColors: true,
                        margin: 8,
                        fontSize: '16px',
                        formatter: function(seriesName, opts) {
                            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex]
                        },
                    },
                }
            },
            colors: ['#e53d51', '#0084ff', '#4caf50', '#ffc107'],
            labels: ['XP Points', 'Level', 'Conversions', 'Performance'],
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        show: false
                    }
                }
            }]
        }
    };

    return (
        <div>
            <Card className="mt-2 mx-2 mb-4 w-full containerD">
                <div style={{ flex: '1', padding: '0.01em 0', overflowY: 'auto' }}>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        XP Balance & Levels
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400" style={{ marginTop: '0.2em', marginBottom: '1.5em' }}>
                        Explore your XP balance and journey through different levels to unlock new achievements and rewards.
                    </p>
                    <div id="chart">
                        <ReactApexChart options={chartData.options} series={chartData.series} type="radialBar" height={350} />
                    </div>
                    <div id="html-dist"></div>
                </div>
            </Card>
        </div>
    );
}

export default XpBalance;
