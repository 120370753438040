import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

import './DetailsAmbassador.css';
import Entete from './Cards/Entete';
import HRInfos from './Cards/HRInfos';
import LastLogins from './Cards/LastLogins';
import Withdrawal from './Cards/Withdrawal';
import ACases from './Cards/ACases';
import { useNavigate , useParams  } from 'react-router-dom';
import UserContext from '../../../API/UserContext';

function DetailsAmbassador(props) {
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    let { id } = useParams();

    const userId = id;
    const Navigate = useNavigate();
    
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (user ===null  ) {
            Navigate('/Login');
        }
        const fetchData = async () => {
            try {
                const token = user.token; // Replace this with your actual JWT token
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get(`https://mood.ovh:5000/Admin/User/GetAllInfos/${userId}`, config);
                setUserData(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                if (error.response && error.response.status === 401) {
                    // Redirect to login page if status code is 401
                    Navigate('/login');
                } else {
                    console.error("Error fetching balance data:", error);
                }
            }
        };

        fetchData();
    }, [userId]);


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userData) {
        return <div>No data available</div>;
    }

    return (
        <div className="md:mx-20 mx-auto my-5 p-5">
            <div className="md:flex no-wrap md:-mx-2 ">
                <div className="w-full md:w-3/12 md:mx-2">
                    <Entete userData={userData} />
                    <div className="my-4"></div>
                    <LastLogins userData={userData} />
                </div>
                <div className="md:mt-0  mt-6  "></div>
                <div className="w-full md:w-9/12   h-64">
                    <HRInfos userData={userData} />
                    <div className="my-4"></div>
                    <ACases userData={userData} />
                    <div className="my-4"></div>
                    <Withdrawal operationsHistory={userData.OperationsHistory} />
                </div>
            </div>
        </div>
    );
}

export default DetailsAmbassador;
