import React, { useContext, useState , useEffect } from 'react';
import axios from "axios"; // Import Axios library

import { Button, Label, TextInput } from "flowbite-react";
import UserContext from '../../../API/UserContext';
import { useNavigate } from 'react-router-dom';

function LoginComponent() {
    const { setUser } = useContext(UserContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [response, setResponse] = useState('');
    const [ip, setIPAddress] = useState('')

    const Navigate = useNavigate();


    useEffect(() => {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => setIPAddress(data.ip))
        .catch(error => console.log(error))
    }, []);


    
    async function loginUser() {
        // Replace the URL with your actual authentication endpoint
        const apiUrl = 'https://mood.ovh:5000/Authentication/Login';

        // Prepare the payload for the request
        const payload = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
          body: JSON.stringify({
                email: email,
                password: password,
                ip:ip
            }),
       
        };

        // Simulate an asynchronous operation using the fetch API
        return new Promise((resolve, reject) => {
            fetch(apiUrl, payload)
                .then(response => {
                    if (!response.ok) {
                        setError('Invalid email or password');
                        //  throw new Error('Login failed');
                    }
                    setResponse(response); // Assuming API sends back a message field
                    return response.json();
                })
                .then(data => {
               //     console.log({ data })
                    const { Ambassador, token } = data;
                    resolve({ Ambassador, token });
                })
                .catch(error => {
                 //   console.log({ error })
                    reject(error);
                });
        });
    }







    const handleLogin = async () => {
        const { Ambassador, token } = await loginUser();
        // Mise à jour du contexte utilisateur avec les données obtenues de l'API
        setUser({ Ambassador, token });
        Navigate('/');



    };

    return (
        <div>
            <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
                <div className="relative py-3 sm:max-w-xl sm:mx-auto">
                    <div
                        className="absolute inset-0 bg-gradient-to-r from-red-300 to-red-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
                    </div>
                    <div className="relative px-4 py-10 bg-white shadow-lg sm:rounded-3xl sm:p-20">
                        <div className="max-w-md mx-auto">
                            <div>
                                <h1 className="text-2xl font-semibold"><span style={{ color: '#e53d51', fontWeight: '800' }}>DN</span> | Ambassadors Login</h1>
                            </div>
                            <div className="divide-y divide-gray-200">
                                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">

                                    <div className="space-y-6">
                                        <div>
                                            <div className="block">
                                                <Label htmlFor="email" value="Your email" />
                                            </div>
                                            <TextInput
                                                id="email"
                                                placeholder="name@company.com"
                                                value={email}
                                                onChange={(event) => setEmail(event.target.value)}
                                                required
                                            />
                                        </div>
                                        <div>
                                            <div className=" block">
                                                <Label htmlFor="password" value="Your password" />
                                            </div>
                                            <TextInput id="password" type="password" value={password} required onChange={(event) => setPassword(event.target.value)} />
                                        </div>
                                        {error && <p className="text-red-500">{error}</p>} {/* Display error message if error state is not empty */}
                                        <div className="w-full ">
                                            <Button className="mx-11" style={{ backgroundColor: '#e53d51' }} onClick={handleLogin}>Log in to your account</Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default LoginComponent;
