import React from "react";
import { Card } from "flowbite-react";
import { List } from "flowbite-react";
import { HiCheckCircle } from "react-icons/hi";
import './style/CrmAcess.css'

function CrmAcess({ userData }) {
    return (
        <div>
            <Card className="mt-2 mx-2 mb-4 w-full containerA">
                <div style={{ flex: '1', padding: '0.01em 0', overflowY: 'auto' }}>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Your <span style={{ color: '#e53d51', fontWeight: 700 }}>Digital</span> Natives CRM Account
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400" style={{ marginTop: '0.2em', marginBottom: '1.5em' }}>
                        Effortlessly manage your <span style={{ color: '#e53d51', fontWeight: 500 }}>Digital</span> Native customer relationships with our intuitive CRM platform.
                    </p>
                    <List>
                        <List.Item style={{ color: '#e53d51' }} icon={HiCheckCircle}>
                            <span style={{ color: '#e53d51', fontWeight: 500 }}>
                                Account Email : <br />
                                <span style={{ color: 'black', fontWeight: 300 }} >
                                    {userData.Email}
                                </span>
                            </span>
                        </List.Item>
                        <List.Item style={{ color: '#e53d51' }} icon={HiCheckCircle}>
                            <span style={{ color: '#e53d51', fontWeight: 500 }}>
                                Account default password : <br />
                                <span style={{ color: 'black', fontWeight: 300 }} >
                                    {userData.CRMDefaultPassword}
                                </span>
                            </span>
                        </List.Item>
                        <List.Item style={{ color: '#e53d51' }} icon={HiCheckCircle}>
                            <span style={{ color: '#e53d51', fontWeight: 500 }}>
                                CRM Authentication : <br />
                                <span style={{ color: 'black', fontWeight: 300 }} >
                                    <a target="_blank" href="https://app.hubspot.com/login">https://app.hubspot.com/login</a>
                                </span>
                            </span>
                        </List.Item>
                    </List>
                </div>
            </Card>
        </div>
    );
}

export default CrmAcess;
