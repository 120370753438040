import React, { createContext, useState } from 'react';

// Création du contexte utilisateur
const UserContext = createContext();

// Définition du fournisseur de contexte utilisateur
export const UserProvider = ({ children }) => {
  // Utilisation de useState pour stocker les données de l'utilisateur
  const [user, setUser] = useState(null);

  return (
    // Fournit le contexte utilisateur et sa valeur (user et setUser) aux composants enfants
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
