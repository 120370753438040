import React  from "react";
import HeaderBar from '../../components/navigationbars/headerbar';
import AmbassadorsListComponent from '../../components/Admin/AmbassadorsList/AmbassadorsListComponent';

 


function AmbassadorsList() {
 
    return (
        <div>
            <HeaderBar pagepath="/Admin/Ambassadors" />
            <AmbassadorsListComponent />

         </div>
    );
}

export default AmbassadorsList;
