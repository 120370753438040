import React from "react";
import { Card } from "flowbite-react";
import { LineChart } from '@mui/x-charts/LineChart';
import './style/TotalBalence.css'
function TotalBalance({ balance }) {
    return (
        <div>
            <Card className="mt-2 mx-2 mb-4 w-full containerE">
                <div style={{ flex: '1', padding: '0.01em 0', overflowY: 'auto' }}>
                    <div className="flex justify-between items-center">
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Your Total Balance
                        </h5>
                        <p className="text-l font-semibold ">TND<span className="text-xl" style={{ color: '#e53d51', marginLeft: '0.2em' }}>{balance.Wallet}</span></p>
                    </div>

                    <p className="font-normal text-gray-700 dark:text-gray-400" style={{ marginTop: '0.2em', marginBottom: '1.5em' }}>
                        Track your financial progress and stay updated with your total balance.
                    </p>

                    <div style={{ width: '100%', height: '35vh' }}>
                        <LineChart
                            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
                            series={[
                                {   
                                    data: [0, 0, 0, 0, 0, 0],
                                },
                            ]}
                            colors={['#e53d51']}
                            backgroundColor='#e53d51'
                        />
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default TotalBalance;
