import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import DataDrops from './cards/DataDrops';
import CrmAcess from './cards/CrmAcess';
import CompanyKit from './cards/CompanyKit';
import PRResources from './cards/PRResources';
import LFAQ from './cards/LFAQ';
import UserContext from '../../../API/UserContext';
import { useNavigate } from 'react-router-dom';

function Balance(props) {
    const [userData, setUserData] = useState(null);
    const [companyKitsData, setCompanyKitsData] = useState([]);
    const [lfaqData, setLfaqData] = useState([]);
    const [communicationResourcesData, setCommunicationResourcesData] = useState([]);
    const Navigate = useNavigate();

    const { user } = useContext(UserContext);

    useEffect(() => {


        if (user === null  ) {
            Navigate('/Login');
        }
        console.log({user})
        const fetchData = async () => {
            try {
                const token = user.token; // Replace this with your actual JWT token
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                };

                const response = await axios.get("https://mood.ovh:5000/User/Tools/GetTools", config);
                const { User, CompanyKits, LFAQ, CommunicationResources } = response.data;
                setUserData(User);
                setCompanyKitsData(CompanyKits);
                setLfaqData(LFAQ);
                setCommunicationResourcesData(CommunicationResources);
            } catch (error) {

                if (error.response && error.response.status === 401) {
                    // Redirect to login page if status code is 401
                    Navigate('/login');
                } else {
                    console.error("Error fetching balance data:", error);
                }
            }
        };

        fetchData();
    }, []);


    return (
        <>
            {userData && (
                <div className="md:mx-20 mx-auto  p-5">
                    <div className="md:flex no-wrap md:mb-0  ">
                        <div className="w-full md:w-4/12 md:mx-2">
                            <CrmAcess userData={userData} />
                            <div className="md:mt-0  mt-6  "></div>
                        </div>
                        <div className="w-full md:w-4/12  md: h-64">
                            <DataDrops data={userData.DataDrops} />
                        </div>
                        <div className="w-full md:w-4/12 md:mx-2 h-64">
                            <div className="md:mb-0 mb-36  "></div>
                            <CompanyKit data={companyKitsData} />
                        </div>
                    </div>
                    <div className="md:flex no-wrap">
                        <div className="w-full md:w-6/12 md:mx-2">
                            <div className="md:mb-0 mb-36  "></div>
                            <PRResources data={communicationResourcesData} />
                        </div>
                        <div className="w-full md:w-6/12 md:mx-2 h-64">
                            <LFAQ data={lfaqData} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Balance;
