import React, { useContext, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Button, Label, Select, Modal } from "flowbite-react";
import axios from "axios";
import UserContext from '../../../../API/UserContext';
import { Toaster, toast } from 'sonner'


function Withdrawal({ operationsHistory }) {
    const [openModal, setOpenModal] = useState(false);
    const [status, setStatus] = useState('');
    const [SelectedRow, setSelectedRow] = useState({ id: '', status: '' });
    const { user } = useContext(UserContext);

    // Mapping operations history data to fit DataGrid rows
    const rows = operationsHistory.map(operation => ({
        id: operation.id,
        created_at: formatDate(operation.created_at),
        Motif: operation.Motif,
        Valeur: `${operation.Valeur}`,
        Type: operation.Type,
        Status: operation.Status,
        AmbassadorID: operation.AmbassadorID
    }));

    const columns = [
        { field: 'created_at', headerName: 'Date Opération', width: 200 },
        { field: 'Motif', headerName: 'Motif', width: 350 },
        {
            field: 'Valeur',
            headerName: 'Valeur',
            width: 200,
            cellClassName: (params) => {
                return `custom-valeur-cell ${params.row.Type === 'Cash-out' ? 'cash-out' : 'cash-in'}`;
            },
        },
        {
            field: 'Type',
            headerName: "Type de l'Opération",
            width: 200,
            cellClassName: (params) => {
                return `custom-valeur-col ${params.row.Type === 'Cash-out' ? 'cash-out' : 'cash-in'}`;
            },
        },
        { field: 'Status', headerName: 'Status', width: 150 },
        {
            field: 'Action',
            headerName: 'Action',
            width: 130,
            renderCell: (params) => {
                const isPending = params.row.Status === 'pending'; // Check if status is 'pending'
                return (
                    <Button
                        onClick={() => handleManageClick(params.row.id, params.row.Status)}
                        style={{ backgroundColor: '#e53d51', marginTop: '3%', marginLeft: '20%' }}
                        pill
                        disabled={!isPending} // Disable button if status is not 'pending'
                    >
                        Manage
                    </Button>
                );
            },
        },
    ];


    function formatDate(dateString) {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('fr-FR', options);
    }
    const handleManageClick = (caseId, caseStatus) => {
        setSelectedRow({ id: caseId, status: caseStatus });
        setStatus(caseStatus); // Set the default status in the select input
        setOpenModal(true);
    };
    const handleSave = () => {
        const UpdatedCase = {
            OperationID: SelectedRow.id,
            NewStatus: status,
        };
        setOpenModal(false);
       /// console.log({ UpdatedCase })
        axios.post('https://mood.ovh:5000/Admin/Operations/Status/update', UpdatedCase, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        })
            .then(response => {
                setOpenModal(false);
                toast.success('Updated ! ');

              //  console.log('Operation updated successfully:', response.data);
                // Optionally, you may want to update the cases list here
            })
            .catch(error => {
                setOpenModal(false);
                toast.error('Error A477 ! ');

                console.error('Error updating Operation:', error);
            });

    };

    return (
        <div>
            <Toaster expand={false} richColors position="top-right" />


            <div className="bg-white p-3 shadow-sm rounded-sm">
                <div className="flex justify-between items-center mb-6 mt-2">
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Operations History
                    </h5>
                </div>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                />
            </div>









            <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)}>
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">Operation Moderation</h3>
                        <div>
                            <Label htmlFor="caseId" value="Operation ID" />
                            <div>{SelectedRow.id}</div>
                        </div>
                        <div>
                            <Label htmlFor="status" value="Current Status" />
                            <div>{SelectedRow.status}</div>
                        </div>
                        <div>
                            <Label htmlFor="status" value="New Status" />
                            <Select id="status" required onChange={(e) => setStatus(e.target.value)}>
                                <option value="pending">Pending</option>
                                <option value="rejected">Rejected</option>
                                <option value="accepted">Accepted</option>
                            </Select>
                        </div>
                        <div className="flex justify-center"> {/* Centering the submit button */}
                            <Button style={{backgroundColor:'#e53d51'}} onClick={handleSave}>Envoyer la demande</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Withdrawal;
