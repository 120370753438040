import React from 'react';
import HeaderBar from '../components/navigationbars/headerbar';
import BalanceComponent from '../components/User/Balance/Balance';


function Balance() {


    return (
        <div>
            <HeaderBar pagepath="/Balance" />


            <BalanceComponent />

        </div>
    );
}

export default Balance;
