import React, { useState, useEffect, useRef, useContext } from "react";
import Avatar from '@mui/material/Avatar';

import { Link } from 'react-router-dom';
import UserContext from '../../API/UserContext';
import { useNavigate } from 'react-router-dom';

function HeaderBar(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
  const avatarRef = useRef(null);
  const { user } = useContext(UserContext);
  console.log(user)
  const Navigate = useNavigate();



  useEffect(() => {

    const handleClickOutside = (event) => {
      if (avatarRef.current && !avatarRef.current.contains(event.target)) {
        setAvatarMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleAvatarMenu = () => {
    setAvatarMenuOpen(!avatarMenuOpen);
  };

  // Define your dynamic links array
  var links = [
    { label: "Home", href: "/" },
    { label: "My Cases", href: "/Cases" },
    { label: "Tools", href: "/Tools" }
  ];

  if (user && user.Ambassador.GestionRH === true) {
    links.push({ label: "Administration", href: "/Admin/Ambassadors" });
  }

  return (
    <div className="" style={{ backgroundColor: '#fff' }} >
      <nav className="flex items-center justify-between flex-wrap p-3" style={{ borderBottom: "1px solid #ccc", width: 'auto' }}  >
        <div className="flex items-center flex-shrink-0 text-black mr-40">
          <Link to={"/"} >

            <img src='/logo-black.png' alt="Logo Digital Natives" className="h-6 w-auto ml-6" />

          </Link>
        </div>
        <div className="hidden lg:flex lg:items-center lg:w-auto lg:justify-center" >
          <div className="text-sm lg:flex-grow" >
            {links.map((link, index) => (
              <Link to={link.href} >
                <a
                  key={index}

                  className={`mr-8 font-bold text-black hover:text-red-500 ${link.href === props.pagepath ? 'text-red-500' : ''
                    }`}
                >
                  {link.label}
                </a>
              </Link>
            ))}
          </div>
        </div>
        <div className="hidden lg:flex lg:items-center lg:w-1/5 lg:justify-end mr-6" ref={avatarRef} onClick={toggleAvatarMenu} >

          {user ? (
            <Avatar alt="Remy Sharp" src={user.Ambassador.PDP} />
          ) : (
            <Avatar alt="Remy Sharp" src='/1.jpg' />
          )}



          {avatarMenuOpen && (
            <div className="absolute right-0 mt-32 bg-white shadow-lg rounded-md py-2 w-60 "
              style={{ zIndex: '10' }}   >
              <span style={{ fontSize: '14px', marginTop: '1.5em', marginLeft: '1em' }}>Welcome back {user  && (user.Ambassador.FirstName+' '+user.Ambassador.LastName)} !</span>
              <button className="block text-left px-4 py-2  hover:bg-gray-200 w-full" onClick={() => Navigate('/login')} style={{ color: '#e53d51' }}>
                Logout
              </button>
            </div>
          )}
        </div>
        <div className="block lg:hidden" >
          <button onClick={toggleMenu} className="flex items-center px-3 py-2 border rounded text-red hover:text-white hover:border-white" style={{ color: '#000', borderColor: '#000' }}>
            <svg className="fill-current h-3 w-3 " viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
          </button>


          <div className={`absolute top-0 left-0 w-full lg:hidden ${menuOpen ? 'block' : 'hidden'}`} style={{ backgroundColor: '#fff', marginTop: '3.40rem', zIndex: '10', borderBottom: '1px solid black', borderBottomLeftRadius: '8px', borderBottomRightRadius: '8px', borderColor: '#df3f51' }}>
            <div className="text-lg ml-6">
              {links.map((link, index) => (
                <Link to={link.href} >
                  <a key={index} className="block mt-4 mr-4 mb-5 ml-2 font-semibold" style={{ color: '#000' }}>
                    {link.label}
                  </a>
                </Link>
              ))}
            </div>
          </div>


        </div>
      </nav>
    </div>
  );
}

export default HeaderBar;
