import React  from "react";
import LoginComponent from '../components/Authentication/Login/loginComponent';



function Login() {
 
    return (
        <div>
            <LoginComponent />
         </div>
    );
}

export default Login;

 