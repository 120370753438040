import React from "react";
import { Card, Table, Button } from "flowbite-react";
import './style/CrmAcess.css'

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr', { year: 'numeric', month: 'short', day: 'numeric' });
}

function CompanyKit({ data }) {
    return (
        <div>
            <Card className="mt-2 mx-2 mb-4 w-full containerA">
                <div style={{ flex: '1', padding: '0.01em 0', overflowY: 'auto' }}>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        <span style={{ color: '#e53d51', fontWeight: 700 }}>Digital</span> Natives On Boarding Kits
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400" style={{ marginTop: '0.2em', marginBottom: '1.5em' }}>
                        Access immersive onboarding kits tailored to acquaint you with our company's operations and processes.
                    </p>
                    <Table style={{ textAlign: 'center' }}>
                        <Table.Head>
                            <Table.HeadCell>File Name</Table.HeadCell>
                            <Table.HeadCell>Date</Table.HeadCell>
                            <Table.HeadCell>Action</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {data.map(row => (
                                <Table.Row key={row.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>{row.FileName}</Table.Cell>
                                    <Table.Cell>{formatDate(row.created_at)}</Table.Cell>
                                    <Table.Cell>
                                        <a href={row.Url} target="_blank"> <Button pill size="sm" style={{ display: 'flex', marginTop: '3%', marginLeft: '20%', backgroundColor: '#e53d51' }}>
                                            Download
                                        </Button></a>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </Card>
        </div>
    );
}

export default CompanyKit;
