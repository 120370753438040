import React, { useContext, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { Button, Label, Select, Modal, TextInput } from "flowbite-react";
import axios from "axios";
import UserContext from '../../../../API/UserContext';
import { Toaster, toast } from 'sonner'

function ACases({ userData }) {
    const [openModal, setOpenModal] = useState(false);
    const [status, setStatus] = useState('');
    const [selectedCase, setSelectedCase] = useState({ id: '', status: '' });
    const [Commission, setCommission] = useState('0');
    const { user } = useContext(UserContext);
 
    const cases = userData?.Cases;

    

    const columns = [
        { field: 'date', headerName: 'Creation Date', width: 130 },
        { field: 'service', headerName: 'Service', width: 130 },
        { field: 'fullname', headerName: 'First & Last Name', width: 200 },
        { field: 'phone', headerName: 'Phone', width: 200 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'status', headerName: 'Status', width: 180 },
        {
            field: 'Action',
            headerName: 'Action',
            width: 130,
            renderCell: (params) => {
                const isPending = params.row.status === 'pending'; // Check if status is 'pending'
                return (
                    <Button
                        onClick={() => handleManageClick(params.row.id, params.row.Status)}
                        style={{ backgroundColor: '#e53d51', marginTop: '3%', marginLeft: '20%' }}
                        pill
                        disabled={!isPending} // Disable button if status is not 'pending'
                    >
                        Manage
                    </Button>
                );
            },
        },
    ];

    const rows = cases.map(acase => ({
        id: acase.id,
        date: new Date(acase.created_at).toLocaleDateString(),
        service: acase.Service,
        fullname: acase.FullName,
        phone: acase.Phone,
        email: acase.Email,
        status: acase.Status,
    }));

    const handleManageClick = (caseId, caseStatus) => {
        setSelectedCase({ id: caseId, status: caseStatus });
        setStatus(caseStatus); // Set the default status in the select input
        setOpenModal(true);
    };

    const handleSave = () => {
        const UpdatedCase = {
            CaseID: selectedCase.id,
            NewStatus: status,
            Commission: Commission
        };
        setOpenModal(false);
       // console.log({ UpdatedCase })
        axios.post('https://mood.ovh:5000/Admin/Cases/Status/update', UpdatedCase, {
            headers: {
                Authorization: `Bearer ${user.token}`
            }
        })
            .then(response => {
                setOpenModal(false);
                toast.success('Updated ! ');

               // console.log('Case status updated successfully:', response.data);
                // Optionally, you may want to update the cases list here
            })
            .catch(error => {
                setOpenModal(false);
                toast.error('Error A478 ! ');

                console.error('Error updating case status:', error);
            });

    };






    return (
        <div className="bg-white p-3 shadow-sm rounded-sm">
                        <Toaster expand={false} richColors position="top-right" />

            <div className="flex justify-between items-center mb-6 mt-2">
                <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Ambassador Cases
                </h5>
            </div>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
            />

            <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)}>
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">Case Moderation</h3>
                        <div>
                            <Label htmlFor="caseId" value="Case ID" />
                            <div>{selectedCase.id}</div>
                        </div>
                        <div>
                            <Label htmlFor="status" value="Current Status" />
                            <div>{selectedCase.status}</div>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="Commission" value="Commission" />
                            </div>
                            <TextInput value={Commission} id="Commission" type="number" required onChange={(e) => setCommission(e.target.value)} />
                        </div>
                        <div>
                            <Label htmlFor="status" />
                            <Select id="status" required onChange={(e) => setStatus(e.target.value)}>
                                <option value="pending">Pending</option>
                                <option value="rejected">Rejected</option>
                                <option value="accepted">Accepted</option>
                            </Select>
                        </div>
                        <div className="flex justify-center"> {/* Centering the submit button */}
                            <Button style={{backgroundColor:'#e53d51'}} onClick={handleSave}>Envoyer la demande</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ACases;
