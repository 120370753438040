import React  from "react";
import HeaderBar from '../../components/navigationbars/headerbar';
import DetailsAmbassadorC from '../../components/Admin/DetailsAmbassador/DetailsAmbassador';



function DetailsAmbassador() {
 
    return (
        <div>
            <HeaderBar pagepath="/Admin/Ambassadors" />

            <DetailsAmbassadorC />

         </div>
    );
}

export default DetailsAmbassador;
