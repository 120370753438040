import React  from "react";
import HeaderBar from '../components/navigationbars/headerbar';

import CasesCard from '../components/User/Cases/CasesCard';



function Dossiers() {


    return (
        <div>
            
            <HeaderBar pagepath="/Cases" />
            
            <CasesCard />

         </div>
    );
}

export default Dossiers;

 