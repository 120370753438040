import React, {  useContext,useState, useEffect } from "react";
import { Card } from "flowbite-react";
import { DataGrid } from '@mui/x-data-grid';
import { Button } from "flowbite-react";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../../API/UserContext';

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr', { year: 'numeric', month: 'short', day: 'numeric' });
}

function AmbassadorsListComponent(props) {
    const [rows, setRows] = useState([]);
    const Navigate = useNavigate();
    
    const { user } = useContext(UserContext);

    useEffect(() => {
        if (user ===null  ) {
            Navigate('/Login');
        }
        const fetchData = async () => {
            try {
                const token = user.token; // Replace this with your actual JWT token
                const headers = {
                    Authorization: `Bearer ${token}`
                };
    
                const response = await fetch('https://mood.ovh:5000/Admin/User/GetAll', {
                    headers: headers
                });
                const data = await response.json();
    
                if (data && data.Ambassadors) {
                    setRows(data.Ambassadors.map(ambassador => ({
                        date: formatDate(ambassador.created_at),
                        id: `#DNA${ambassador.id}`,
                        fullname: `${ambassador.FirstName} ${ambassador.LastName}`,
                        phone: ambassador.Phone,
                        email: ambassador.Email,
                        status: ambassador.Moderation,
                        market: ambassador.AssignedMarket,
                        AmbassadorID: ambassador.id,
                        nbpendingcases: data.Cases.length
                    })));
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    // Redirect to login page if status code is 401
                    Navigate('/login');
                } else {
                    console.error("Error fetching balance data:", error);
                }  
            }
        };
    
        fetchData();
    }, []);
    

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'fullname', headerName: 'First & Last Name', width: 220 },
        { field: 'phone', headerName: 'Phone', width: 130 },
        { field: 'email', headerName: 'Email', width: 260 },
        { field: 'market', headerName: 'Market', width: 200 },
        { field: 'status', headerName: 'Status', width: 180 },
        { field: 'date', headerName: 'Creation Date', width: 150 },
        { field: 'nbpendingcases', headerName: 'Pending Cases', width: 150 },

        {
            field: 'AmbassadorID', headerName: 'Action', width: 130, renderCell: (params) => {
                return <Link to={`/Admin/Ambassador/${params.value}`} ><Button style={{ backgroundColor: '#e53d51', marginTop: '3%', marginLeft: '20%' }} pill>More</Button></Link>;
            }
        },
    ];

    return (

        <div>

            <Card className="mt-2" style={{ height: '90vh', backgroundColor: '#fff', display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: '1', padding: '1em 0', overflowY: 'auto' }}>
                    <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        <span style={{ color: '#e53d51', fontWeight: 700 }}>Digital</span> Natives <span style={{ color: '#e53d51', fontWeight: 700 }}>Ambassadors</span>
                    </h5>
                    <p className="font-normal text-gray-700 dark:text-gray-400" style={{ marginTop: '0.2em', marginBottom: '1.5em' }}>
                        Explore a comprehensive overview of active and pending approval digital natives ambassadors.
                    </p>

                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        style={{ height: '42em' }}
                    />
                </div>
            </Card>

            <style jsx>{`
                @media (min-width: 640px) {
                    .mt-2 {
                        margin-left: 6em;
                        margin-right: 6em;
                    }
                }
            `}</style>
        </div>
    );
}

export default AmbassadorsListComponent;
