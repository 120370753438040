import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
 import AddIcon from '@mui/icons-material/Add';
import { Button,Label, Modal, TextInput, Select } from "flowbite-react";
import { DataGrid } from '@mui/x-data-grid';
import { Card } from "flowbite-react";
import UserContext from '../../../API/UserContext';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'sonner'

const columns = [
    { field: 'date', headerName: 'Creation Date', width: 200 },
    { field: 'id', headerName: 'N° Dossier', width: 200 },
    { field: 'service', headerName: 'Service', width: 250 },
    { field: 'fullname', headerName: 'First & Last Name', width: 300 },
    { field: 'phone', headerName: 'Phone', width: 200 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'status', headerName: 'Status', width: 180 },
];
 
function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB'); // Format: dd/mm/yyyy
}

function CasesCard(props) {
    const [openModal, setOpenModal] = useState(false);
    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("United States");
    const [service, setService] = useState("Web Dev"); // New state variable for service
    const [cases, setCases] = useState([]);
    const { user } = useContext(UserContext);
    const Navigate = useNavigate();


    const fetchCases = async () => {
        try {
            const token = user.token; // Replace this with your actual JWT token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const response = await axios.get("https://mood.ovh:5000/User/Casses/GetAllCases", config);
            setCases(response.data.Cases);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Redirect to login page if status code is 401
                Navigate('/login');
            } else {
                console.error("Error fetching balance data:", error);
            }
        }
    };
    useEffect(() => {

        if (user === null) {
            Navigate('/Login');
        }



        fetchCases();

    }, []);


    const handleInsert = () => {
        if (fullname && fullname != "" && email && email != "" && phone && phone != "" && country && country != "" && service && service != "") {

            const newCase = {
                fullname: fullname,
                email: email,
                phone: phone,
                country: country,
                service: service
            };

            axios.post('https://mood.ovh:5000/User/Cases/Insert', newCase, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            })
                .then(response => {
                    setOpenModal(false);
                    fetchCases();

                    toast.success('Sent ! ');

                 //   console.log('Case inserted successfully:', response.data);
                    // Optionally, you may want to update the cases list here
                })
                .catch(error => {
                    setOpenModal(false);
                    fetchCases();
                    toast.error('Error E1455');

                    console.error('Error inserting case:', error);
                });
        } else {
            toast.error('Please Fill all the inputs')

        }
    };


    const rows = cases.map(c => ({
        id: '#DNC' + c.id,
        date: formatDate(c.created_at),
        service: c.Service,
        fullname: c.FullName,
        phone: c.Phone,
        email: c.Email,
        status: c.Status,
    }));

    return (
        <div>
            
            <Toaster expand={false} richColors position="top-right" />

            <Card className="mt-2" style={{ height: '90vh', backgroundColor: '#fff', display: 'flex', flexDirection: 'column' }}>
                <div style={{ flex: '1', padding: '1em 0', overflowY: 'auto' }}>
                    <div className="flex justify-between items-center  mb-12">
                        <div>
                            <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                Your Cases
                            </h5>
                            <p>Monitor prospects transferred to the sales team for streamlined conversion tracking.</p>
                        </div>
                        <p className="text-l font-semibold ">
                            <Button variant="contained" style={{ backgroundColor: '#e53d51' }} onClick={() => setOpenModal(true)} startIcon={<AddIcon />}>Insert Case</Button>
                        </p>
                    </div>

                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={5}
                        style={{ height: '42em' }}
                    />
                </div>
            </Card>

            <Modal show={openModal} size="md" popup onClose={() => setOpenModal(false)}>
                <Modal.Header />
                <Modal.Body>
                    <div className="space-y-6">
                        <h3 className="text-xl font-medium text-gray-900 dark:text-white">Case details</h3>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="fullname" value="Full Name" />
                            </div>
                            <TextInput placeholder="First and last name" id="fullname" type="text" required value={fullname} onChange={(e) => setFullname(e.target.value)} />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="email" value="Email" />
                            </div>
                            <TextInput id="email" type="email" placeholder="Ex. foulenbenfalten@domaine.com" required value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="phone" value="Phone Number" />
                            </div>
                            <TextInput id="phone" type="text" placeholder="Ex. 58 158 739" required value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="pays" value="Country" />
                            </div>
                            <Select id="pays" required value={country} onChange={(e) => setCountry(e.target.value)}>
                                <option value="United States">United States</option>
                                <option value="Canada">Canada</option>
                                <option value="France">France</option>
                                <option value="Germany">Germany</option>
                            </Select>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="service" value="Service" />
                            </div>
                            <Select id="service" required value={service} onChange={(e) => setService(e.target.value)}>
                                <option value="Web Dev">Web Dev</option>
                                <option value="Mobile Dev">Mobile Dev</option>
                                <option value="Software Dev">Software Dev</option>
                                <option value="Data Science">Data Science</option>
                                <option value="Data Analyses">Data Analyses</option>
                            </Select>
                        </div>
                        <div className="flex justify-center"> {/* Centering the submit button */}
                            <Button style={{backgroundColor:'#e53d51'}} onClick={handleInsert}>Envoyer la demande</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <style jsx>{`
                @media (min-width: 640px) {
                    .mt-2 {
                        margin-left: 6em;
                        margin-right: 6em;
                    }
                }
            `}</style>
        </div>
    );
}

export default CasesCard;
