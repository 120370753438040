import React from "react";
import { Card, Table, Button, Dropdown } from "flowbite-react";
import './style/LFAQ.css'

function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr', { year: 'numeric', month: 'short', day: 'numeric' });
}

function PRResources({ data }) {
    return (
        <div>
            <Card className="mt-2 mx-2 mb-4 w-full containerC">
                <div style={{ flex: '1', padding: '0.01em 0', overflowY: 'auto' }}>
                    <div className="flex justify-between items-center">
                        <h5 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            <span>Resources for your <span style={{ color: '#e53d51', fontWeight: 700 }}> communication </span> </span>
                        </h5>
                    </div>
                    <p className="font-normal text-gray-700 dark:text-gray-400" style={{ marginTop: '0.2em', marginBottom: '1.5em' }}>
                        Access exclusive resources to enhance your communication strategy across different platforms.
                    </p>
                    <Table style={{ textAlign: 'center' }}>
                        <Table.Head>
                            <Table.HeadCell>Platform</Table.HeadCell>
                            <Table.HeadCell>Related Service</Table.HeadCell>
                            <Table.HeadCell>Date</Table.HeadCell>
                            <Table.HeadCell>Action</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {data.map(row => (
                                <Table.Row key={row.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell>{row.platform}</Table.Cell>
                                    <Table.Cell>{row.RelatedService}</Table.Cell>
                                    <Table.Cell>{formatDate(row.created_at)}</Table.Cell>
                                    <Table.Cell>
                                        <Dropdown label="Download" dismissOnClick={false} style={{ display: 'flex', marginTop: '5%', marginLeft: '20%', backgroundColor: '#e53d51' }}>
                                            <Dropdown.Item>
                                                <a href={row.Instagram} target="_blank">For Instagram</a>
                                            </Dropdown.Item>
                                            <a href={row.Facebook} target="_blank">For Facebook</a>
                                            <Dropdown.Item>
                                                <a href={row.LinkedIn} target="_blank">For LinkedIn</a>
                                            </Dropdown.Item>
                                        </Dropdown>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            </Card>
        </div>
    );
}

export default PRResources;
