import React from "react";

function HRInfos({ userData }) {
    const ambassadorData = userData?.Ambassador[0];

    if (!ambassadorData) {
        return null; // or display a loading/error message
    }

    const hrData = {
        "First Name": ambassadorData.FirstName,
        "Last Name": ambassadorData.LastName,
        "Gender": ambassadorData.Gender,
        "Contact No.": ambassadorData.Phone,
        "Current Address": `${ambassadorData.City}, ${ambassadorData.Pays}`,
        "Email": ambassadorData.Email,
        "Birthday": new Date(ambassadorData.Birthday).toLocaleDateString(),
    };

    return (
        <div>
            <div className="bg-white p-3 shadow-sm rounded-sm">
                <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                    <span clas="text-green-500">
                        <svg className="h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                    </span>
                    <span className="tracking-wide">HR Informations</span>
                </div>
                <div className="text-gray-700">
                    <div className="grid md:grid-cols-2 text-sm">
                        {Object.entries(hrData).map(([key, value]) => (
                            <div className="grid grid-cols-2" key={key}>
                                <div className="px-4 py-2 font-semibold">{key}</div>
                                <div className="px-4 py-2">{value}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HRInfos;
