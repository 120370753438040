import React  from "react";
import HeaderBar from '../components/navigationbars/headerbar';
import ToolsComponents from '../components/User/Tools/ToolsComponents';




function Tools() {
 
    return (
        <div>
            <HeaderBar pagepath="/Tools" />
            <ToolsComponents />

         </div>
    );
}

export default Tools;

 
 