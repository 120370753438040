import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import TotalBalance from './cards/TotalBalence';
import XpBalance from './cards/XpBalance';
import OperationsHistory from './cards/OperationsHistory';
import UserContext from '../../../API/UserContext';
import { useNavigate } from 'react-router-dom';
 

function Balance() {
    const [balanceData, setBalanceData] = useState(null);
    const [historyData, setHistoryData] = useState(null);
    const { user } = useContext(UserContext);
    const Navigate = useNavigate();
  
    const fetchBalanceData = async () => {
        try {
            const token = user.token; // Replace this with your actual JWT token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const response = await axios.get("https://mood.ovh:5000/User/Balance/GetBalance", config);
            setBalanceData(response.data.User[0]);
            setHistoryData(response.data.OperationsHistory);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Redirect to login page if status code is 401
                Navigate('/login');
            } else {
                console.error("Error fetching balance data:", error);
            }
        }
    };
    useEffect(() => {
        if (user ===null  ) {
            Navigate('/Login');
        }
       
        fetchBalanceData();
    }, []);
  
    

        

    return (
        <>
            {balanceData && historyData && (
                <div className="p-5">
                    <div className="md:flex no-wrap md:mb-0 md:mx-9 md:mr-4">
                        <div className="w-full md:w-6/12">
                            <TotalBalance balance={balanceData} operationsHistory={historyData} />
                            <div className="md:mt-0 mt-6 md:mx-9"></div>
                        </div>
                        <div className="w-full md:w-6/12 md:h-64 md:mx-9">
                            <XpBalance balance={balanceData} />
                        </div>
                    </div>
                    <div className="md:flex no-wrap">
                        <div className="w-full md:w-12/12">
                            <div className="md:mb-0 mb-8"></div>
                            <OperationsHistory history={historyData} balance={balanceData} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Balance;
