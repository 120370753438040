import './App.css';
import { Route, Routes } from "react-router-dom";
import Balance from './pages/Balance';
import Cases from './pages/Cases';
import Login from './pages/login';
 
import Tools from './pages/Tools';
import Ambassadors from './pages/Admin/AmbassadorsList';
import DetailsAmbassador from './pages/Admin/DetailsAmbassador';
import { UserProvider } from './API/UserContext';
import { Toaster, toast } from 'sonner'

function App() {
  return (
    <div className="App">
      <UserProvider>
        <Routes>
          <Route path="/" element={<Balance />} />
          <Route path="/Login" element={<Login />} />

 
           <Route path="/Balance" element={<Balance />} />
          <Route path="/Cases" element={<Cases />} />
          <Route path="/Tools" element={<Tools />} />
          <Route path="/Admin/Ambassadors" element={<Ambassadors />} />
          <Route path="/Admin/Ambassador/:id" element={<DetailsAmbassador />} />

        </Routes>
      </UserProvider>
    </div>
  );
}

export default App;
