import React from "react";
function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('fr', { year: 'numeric', month: 'short', day: 'numeric' });
}
function Entete({ userData }) {
    const ambassadorData = userData?.Ambassador[0];
    if (!ambassadorData) {
        return null;
    }
    return (
        <div>
            <div className="bg-white p-3 border-t-4" style={{ borderColor: '#e53d51' }}>
                <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">{ambassadorData.FirstName} {ambassadorData.LastName}</h1>
                <h4 className="text-gray-600 font-lg text-semibold leading-6">
                    <strong style={{ color: 'black' }}>Last Login :</strong> <br />
                    {userData?.Authentications[0] ? (
                        `${formatDate(userData.Authentications[0].created_at)} | ${userData.Authentications[0].IP}`
                    ) : (
                        "No login data available"
                    )}
                </h4>
                <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                    <li className="flex items-center py-3">
                        <span>Status</span>
                        <span className="ml-auto">
                            <span className="py-1 px-2 rounded text-white text-sm" style={{ backgroundColor: 'green' }}>
                                {ambassadorData.Moderation}
                            </span>
                        </span>
                    </li>
                    <li className="flex items-center py-3">
                        <span>Member since</span>
                        <span className="ml-auto">
                            {new Date(ambassadorData.created_at).toLocaleDateString()}
                        </span>
                    </li>
                    <li className="flex items-center py-3">
                        <span>Wallet</span>
                        <span className="ml-auto">
                            {ambassadorData.Wallet} TND
                        </span>
                    </li>
                    <li className="flex items-center py-3">
                        <span>XP</span>
                        <span className="ml-auto">
                            {ambassadorData.XP} Points
                        </span>
                    </li>
                    <li className="flex items-center py-3">
                        <span>Level</span>
                        <span className="ml-auto">
                            {ambassadorData.Level} 
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Entete;
